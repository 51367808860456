import * as Dapp from "@elrondnetwork/dapp";
import { useHistory } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import bg from "assets/img/landing.png";
import BrandImage from "assets/img/maiarpunks-logo.png";

const Home = () => {
  const { loggedIn } = Dapp.useContext();
  const history = useHistory();
  const { t } = useTranslation();
  if (loggedIn) {
    history.replace("/sale");
  }

  const dappLogin = Dapp.useWebWalletLogin({
    callbackRoute: "/sale",
    token: undefined,
  });
  const logIn = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogin();
  };

  return (
    <div className="landing-container">
      <img src={bg} alt="background_image" className="landing-bg-image" />
      <div className="landing-brand">
        <img alt="brand_image" src={BrandImage} />
      </div>
      {/* <span className="landing-titles">
        <h1 className="display-5">{t("Calling all heroes")}...</h1>
        <h1 className="display-5">{("The Gnogenverse is coming")}...</h1>
      </span> */}
      <span className="landing-button">
        <button onClick={logIn}>
          <span className="text-white">{t("CLAIM YOUR GNOGON")}</span>
        </button>
      </span>
    </div>
  );
};

export default Home;
